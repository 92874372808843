import create, { SetState, GetState } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface CsomagState {
  csomag: SajatCsomag;
  setCsomagTipus: (csomagtipus: CsomagTipus) => void;
  setMiniProjekt: (key: keyof MiniProjekt) => void;
  setKampany: (key: keyof Kampany) => void;
  setHavicsomag: (key: keyof HaviCsomag) => void;
  printCsomag: () => string;
}

const csomagStore: (
  set: SetState<CsomagState>,
  get: GetState<CsomagState>
) => CsomagState = (set, get) => ({
  csomag: {
    csomagTipus: "miniprojekt",
    miniprojekt: {
      onlineEsOfflineATLBTL: false,
      onlineMarketing: false,
      kampanyok: false,
      kommunikacio: false,
      pr: false,
      reklamugynoksegiTevekenyseg: false,
      piackutatas: false,
      nyomdaiAnyagok: false,
      megvalositas: false,
      oktatas: false,
      tartalomkeszites: false,
      fotozas: "nem választható",
      video: "nem választható",
      grafikaiMunka: "nem választható",
      strategiaAlkotas: "nem választható",
    },
    kampany: {
      onlineEsOfflineATLBTL: false,
      onlineMarketing: false,
      kampanyok: false,
      kommunikacio: false,
      pr: false,
      reklamugynoksegiTevekenyseg: false,
      piackutatas: false,
      nyomdaiAnyagok: false,
      megvalositas: false,
      oktatas: false,
      tartalomkeszites: false,
      fotozas: false,
      video: "nem választható",
      grafikaiMunka: "nem választható",
      strategiaAlkotas: "nem választható",
    },
    havicsomag: {
      onlineEsOfflineATLBTL: false,
      onlineMarketing: false,
      kampanyok: false,
      kommunikacio: false,
      pr: false,
      reklamugynoksegiTevekenyseg: false,
      piackutatas: false,
      nyomdaiAnyagok: false,
      megvalositas: false,
      oktatas: false,
      tartalomkeszites: false,
      fotozas: false,
      video: false,
      grafikaiMunka: false,
      strategiaAlkotas: false,
    },
  },

  setCsomagTipus: (csomagTipus) => {
    set((state: CsomagState) => {
      return { ...state, csomag: { ...state.csomag, csomagTipus } };
    });
  },

  setMiniProjekt: (key) => {
    set((state: CsomagState) => {
      if (state.csomag.miniprojekt[key] === "nem választható") return state;
      return {
        ...state,
        csomag: {
          ...state.csomag,
          miniprojekt: {
            ...state.csomag.miniprojekt,
            [key]: !state.csomag.miniprojekt[key],
          },
        },
      };
    });
  },

  setKampany: (key) => {
    set((state: CsomagState) => {
      if (state.csomag.kampany[key] === "nem választható") return state;
      return {
        ...state,
        csomag: {
          ...state.csomag,
          kampany: {
            ...state.csomag.kampany,
            [key]: !state.csomag.kampany[key],
          },
        },
      };
    });
  },

  setHavicsomag: (key) => {
    set((state: CsomagState) => {
      return {
        ...state,
        csomag: {
          ...state.csomag,
          haviCsomag: {
            ...state.csomag.havicsomag,
            [key]: !state.csomag.havicsomag[key],
          },
        },
      };
    });
  },

  printCsomag: () => {
    const csomi = get().csomag;
    const tipus = csomi.csomagTipus;
    let out = `Együttműködési típus: ${tipus}
    Érdeklődés: `;
    for (const [key, value] of Object.entries(csomi[tipus])) {
      if (value === true) out += `${key}, `;
    }
    return out;
  },
});

export const useCsomagStore = create(
  devtools(persist(csomagStore, { name: "sajatCsomag" }))
);
